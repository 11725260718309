var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-shadow v-card-grey px-0"},[_c('v-card-title',{staticClass:"pt-0 pb-0 card-title-bloque"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t('Auditoría'))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{staticClass:"pb-0 mb-0",attrs:{"items":_vm.chanels,"item-text":"name","item-value":"id","label":_vm.$t('Canal'),"dense":"","flat":"","filled":"","solo":"","height":"43","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}]),model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=$$v},expression:"channel"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('v-select',{staticClass:"pb-0 mb-0",attrs:{"items":_vm.events,"item-text":"name","item-value":"id","label":_vm.$t('Evento'),"dense":"","flat":"","filled":"","solo":"","height":"43","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]}}]),model:{value:(_vm.eventId),callback:function ($$v) {_vm.eventId=$$v},expression:"eventId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"2"}},[_c('date-range',{staticClass:"rangodefechas",attrs:{"prepend-icon":"fas fa-calendar","global-control":_vm.globalControl,"max-date":_vm.maxDate},on:{"onSelectDates":_vm.selectDates,"onInitSelection":_vm.setInitDate,"onResetDates":_vm.onResetDates}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"\n                    input-style\n                    font-size-input\n                    text-light-input\n                    placeholder-light\n                    input-icon\n                  ",attrs:{"dense":"","flat":"","filled":"","solo":"","placeholder":_vm.$t('Destinatario')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}})],1),_c('v-col',{staticClass:"pr-10 mb-0 pb-0",attrs:{"cols":"12","md":"1","lg":"1"}},[_c('v-btn',{staticClass:"d-flex d-md-none mb-2",attrs:{"block":"","elevation":"0","rounded":"","small":"","color":"light-blue darken-2 white--text ","disabled":_vm.content.length === 0},on:{"click":function($event){return _vm.exportCsv()}}},[_vm._v(" "+_vm._s(_vm.$t("Descargar"))+" "),_c('v-icon',{staticClass:"btn-submenu-barra ml-3"},[_vm._v(" fas fa-download ")])],1),_c('v-btn',{staticClass:" pt-6 d-none d-md-flex",attrs:{"icon":"","small":"","color":"blue","elevation":"0","disabled":_vm.content.length === 0},on:{"click":function($event){return _vm.exportCsv()}}},[_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"btn-submenu-barra"},'v-icon',attrs,false),on),[_vm._v(" fas fa-download ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Descargar"))+" ")])])],1)],1)],1)],1),(!_vm.contact)?_c('v-row',[_c('v-col',{staticClass:"px-7"},[_c('v-alert',{staticClass:"mt-n2",attrs:{"color":"blue","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t("Para realizar una búsqueda, introduzca un contacto en el buscador."))+" ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:" pt-0 mt-0",attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15, 25, 50, 100], 'disable-pagination': true, 'items-per-page-text': _vm.$t('Items por página') },"disable-pagination":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.content,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"loading-text":_vm.$t('Cargando datos. Por favor, espere.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("dateLocal")(item.created_at))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("dateLocal")(item.created_at)))])])]}},{key:"item.statusGuid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text d-block text-center ",attrs:{"small":"","label":"","color":_vm.statusesColors[item.statusGuid].color}},[_vm._v(" "+_vm._s(_vm.$t(item.statusGuid))+" ")])]}},{key:"item.channel_id",fn:function(ref){
var item = ref.item;
return [(item.channel_id === 1)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","left":""}},'v-icon',attrs,false),on),[_vm._v(" fas fa-sms ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('SMS')))])])],1):_vm._e(),(item.channel_id === 2)?_c('div',[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v(" fas fa-envelope ")]),_vm._v(" MAILING ")],1):_vm._e(),(item.channel_id === 7 || item.channel_id === 4)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","left":""}},'v-icon',attrs,false),on),[_vm._v(" fas fa-phone ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Voz')))])])],1):_vm._e(),(item.channel_id === 8)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","left":""}},'v-icon',attrs,false),on),[_vm._v(" fab fa-whatsapp ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('WhatsApp')))])])],1):_vm._e()]}},{key:"item.event_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text d-block text-center ls-0",attrs:{"small":"","label":"","color":_vm.getEvent(item).color}},[_vm._v(" "+_vm._s(_vm.getEvent(item).name)+" ")])]}},{key:"item.sent_id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table",attrs:{"height":"25","small":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.goToStats(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-chart-pie"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Estadísticas')))])])]}}])})],1)],1),_c('v-row',{staticStyle:{"margin-top":"-70px"}},[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"circle":""},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }